import React from 'react';

import StandardPage from '../components/StandardPage';
import Head from '../components/Head';

export default () => (
  <StandardPage>
    <Head
      title="Compliance at Bagel"
      description="Innovation in healthcare requires trust. Assuring the privacy and security of patient data
          is at the core of our mission. Bagel Health is fully compliant with the HIPAA/HITECH
          regulations, as updated by the Omnibus Rule, and is also fully PCI Compliant."
      url="/compliance/"
      image="/og_image.jpg"
    />
    <div className="section col ac jc bl60 pmargin">
      <div className="readable">
        <h2 className="title">Compliance</h2>
        <h4 className="title">Bagel Health, Inc.</h4>
        <br />
        <p>
          Innovation in healthcare requires trust. Assuring the privacy and security of patient data
          is at the core of our mission. Bagel Health is fully compliant with the HIPAA/HITECH
          regulations, as updated by the Omnibus Rule, and is also fully PCI Compliant.
        </p>
        <br />
        <h5 className="mt32">HIPAA/HITECH </h5>
        <p>
          Bagel Health has instituted safeguards, policies, and procedures to protect patients’
          health information, in compliance with the final rule issued by the United States
          Department of Health and Human Services regarding the Health Insurance Portability and
          Accountability Act of 1996 (HIPAA) and the Health Information Technology for Economic and
          Clinical Health Act (HITECH). These steps include:
        </p>
        <ul className="ml32">
          <li>
            Ongoing assessments of risks to the confidentiality, integrity, and availability of
            patient data.
          </li>

          <li>
            Implementation of policies and procedures that dictate acceptable work practices and map
            directly to the HIPAA Security Rule’s Administrative, Physical, and Technical
            Safeguards.
          </li>

          <li>
            Implementation of procedural and technical safeguards to prevent Bagel Health employees
            from improperly accessing PHI.
          </li>

          <li>
            Designation of a Chief Security Officer responsible for information system monitoring
            and information security policy oversight.
          </li>

          <li>Mandatory HIPAA privacy and security training for all workforce members.</li>

          <li>
            Encryption of patient data at rest and in transit according to industry-best security
            standards.
          </li>

          <li>Implementation of audit trail and record retention capabilities.</li>
          <li>
            Execution of Business Associate Agreements with customers, vendors, and subcontractors,
            where appropriate.
          </li>
          <li>
            Regular reassessment of all policies and procedures to ensure that HIPAA/HITECH rules
            continue to be addressed.
          </li>
        </ul>
        <h5 className="mt32">PCI-DSS</h5>
        <p>
          Our partner Stripe has been audited by a PCI-certified auditor, and is certified to PCI
          Service Provider Level 1. This is the most stringent level of certification available.
        </p>

        <p>
          Stripe forces HTTPS for all services, including through the Bagel Health service and they
          regularly audit the details of our implementation.
        </p>

        <p>
          All card numbers are encrypted on disk with AES-256. Decryption keys are stored on
          separate machines. None of Stripe's internal servers and daemons are able to obtain
          plaintext card numbers; instead, they can just request that cards be sent to a service
          provider on a static whitelist. Stripe's infrastructure for storing, decrypting, and
          transmitting card numbers runs in separate hosting infrastructure, and doesn't share any
          credentials with Stripe's primary services (API, website, etc.).
        </p>
        <h5 className="mt32">Confidentiality assurance</h5>
        <p>
          We approach compliance and security as a continuous cycle. Our NIST SP 800-30 Rev 1 risk
          assessment drives our organizational policies and procedures, which in turn drive our
          training cycle. We use operational feedback to continuously refine and improve our risk
          posture. All of our operational security metrics are monitored continuously and our
          compliance status is available in real time, 24/7.
        </p>
        <p className="small bl40 mt32">Updated 6 December, 2018</p>
      </div>
    </div>
  </StandardPage>
);
